// change the theme
$theme-colors: (
  "primary": #D28959,
  "secondary": #6c757d,
  "success": #90be6d,
  "info": #43aa8b,
  "warning": #f9c74f,
  "danger": #c43240,
  "dark": #525252,
  'black': #5e3e28,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.cdnfonts.com/css/giliams');
@import url('https://fonts.cdnfonts.com/css/bokor');
// import poppins
@import url('https://fonts.googleapis.com/css?family=Bokor');

body {
  margin: 0;
}
a {
  color: #D28959;
  text-decoration: none;
}
.title-slider {
  text-align: center;
  font-family: 'Bokor', cursive;
}
.description-slider {
  text-align: center;
  color: #fff;
  padding: 20px;
  font-weight: 400;
}
// ACCORDION
.accordion-button{
  background-color: #D28959 !important;
  color: #fff !important;
  font-weight: 700 !important;
  border: none !important;
}
.title {
  font-family: 'Giliams', sans-serif;
  font-weight: 700;
  font-size: 30px;
}
.title-14 {
  font-family: 'Giliams', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #2b2b2b;
}
.text {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.bg-home {
  background-color: #000;
  width: 100%;
  padding: 20px 0px 20px 0px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}
.title-home{
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  color: #fff;
}
.font-weight-700{
  font-weight: 700;
}
.cart-title {
  font-family: 'Giliams', sans-serif;
  font-weight: 700;
  font-size: 40px;
  color: #000;
  padding: 50px 0px 50px 0px;
  background-color: #F5F0EC;
}

.cart-title2 {
  font-family: 'Giliams', sans-serif;
  font-weight: 700;
  font-size: 25px;
  color: #000;
  padding: 30px 0px 30px 0px;
  background-color: #dadada;
}

.cart-subtitle2 {
  font-family: 'Giliams', sans-serif;
  text-decoration: underline;
  font-size: 20px;
  font-weight: 600;
  color: #000;
}
.cart-subtitle {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #000;
}
.cart-description {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.cart-price {
  font-family: 'Poppins', sans-serif;
  margin-top: 10px;
  font-weight: 700;
  color: #D28959;
}

/* Filled Rectangular Button */
.btn-cart {
  background-color: #D28959;
  border: 3px solid #D28959;
  color: transparent;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 20px;
  padding: 10px 20px;
  margin-top: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0px;
}
.btn-cart:hover {
  background-color: #ffe3d0;
  color: #D28959 !important;
  border: 3px solid #ffe3d0 !important;
}

/* Outline Rectangular Button */
.btn-cart-outline {
  background-color: transparent;
  color: #D28959 !important;
  border: 3px solid #D28959;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 20px;
  padding: 10px 20px;
  margin-top: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0px;
}

.btn-cart-outline:hover {
  background-color: #ffe3d0;
  color: #D28959 !important;
  border: 3px solid #ffe3d0 !important;
}

.whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  width: 50px;
}

.decor-image {
  position: fixed;
  top: 5em;
  right: -4em;
  width: 150px;
  opacity: 0.5;
  z-index: 998;
}

.decor-image2 {
  position: fixed;
  top: 37em;
  bottom: 0;
  right: 18em;
  width: 150px;
  opacity: 0.5;
}



// footer style
.footer {
  color: #fff;
  padding: 50px 0px 50px 0px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

// MenuDropdown.scss
.topbar {
  padding: 5px;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-family: 'Giliams', sans-serif;
  background-color: #fff;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  .navbar-brand {
    img {
      width: 100px;
      height: auto;
    }
  }

  .navbar-toggler {
    color: #2a2a2a;
    border: none;
    background: transparent;
    font-size: 24px;
  }

  .navbar-collapse {
    justify-content: flex-end;

    .nav-link {
      color: #191919;
      padding: 10px 15px;
      border-radius: 5px;
      transition: background-color 0.3s ease;

      &.active,
      &:hover {
        background-color: #fff;
        color: #D28959;
      }
    }
  }
}

// mobile and tablet view
@media (max-width: 768px) {
  .offCanvas {
    background-color: #fff !important;
    width: 15em !important;
  }  
}
/* Estilos del banner */
.banner {
  position: relative;
  background-color: #000;
}
.custom-row {
  margin: 0;
  padding: 0;
}

.BannerTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Estilos del contenido */
.Content {
  margin-top: 30px;
}

.Content h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

.Content p {
  font-size: 1.2rem;
  color: #666;
}

/* Estilos de las imágenes */
.ImageCorner {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.ImageCorner::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 10px solid #fff;
}

.video-banner {
  width: 100%;
}

.button-container {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 15px;
  z-index: 1;
}

.video-button {
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
}

.video-button:hover {
  opacity: 0.8;
}

.video-button:nth-child(1) {
  background-color: #007bff; /* Change this to your desired color for the "Carta" button */
  color: #ffffff;
  border: none;
}

.video-button:nth-child(2) {
  background-color: #6c757d; /* Change this to your desired color for the "Reserva" button */
  color: #ffffff;
  border: none;
}
